@charset "utf-8";
@use "../global/_mixin" as g;

@media screen and (max-width: 767.499px) {
/* ============================================================ */
/* contact */
/* ============================================================ */
.contact {
	&__inner {
		text-align: center;
		font-size: 1.7rem;
		line-height: 1.5;
	}
	
	&__tel {
		color: g.$pink;
		font-weight: 600;
		
		.tel {
			font-size: 2.3rem;
			
			a {
				color: g.$pink;
			}
		}
	}
	
}

}