@charset "utf-8";
@use "../global/_mixin" as g;

@media print, screen and (min-width: 767.5px) {
/* ============================================================ */
/* action */
/* ============================================================ */
.action {
	.flex-column >* {
    flex: auto;
	}
	
	&__img {
		max-width: 450px;
	}
	
	&__txt {
		max-width: 500px;
	}
	
	.u-mr50 {
		@media screen and (max-width: 1055px) {
			margin-right: 30px !important;
		}
	}
}

}