@charset "utf-8";
@use "../global/_mixin" as g;

@media screen and (max-width: 767.499px) {
/* ============================================================ */
/* action */
/* ============================================================ */
.action {
	.flex-column >* {
    flex: auto !important;
	}
	
	&__img {
		width: 100%;
	}
	
	.btn {
		&--club {
			width: 100% !important;
			height: 65px;
		}
	}
}

}