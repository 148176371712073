@charset "utf-8";
@use "../global/_mixin" as g;

@media screen and (max-width: 767.499px) {
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	padding: 0 0 30px;
	
	&__inner {
		background: g.$white;
		margin: -90px 15px 0;
		padding: 20px 20px 30px;
		@include g.radius(5);
	}
}





/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 20px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	display: flex;
	align-items: center;
	height: 300px;
	background: g.$baseColor;
	color: g.$white;
	font-size: 2.6rem;
	font-weight: 600;
	line-height: 1.2;
	padding: 0 15px;
}

.ttl02 {
	margin: 0 0 25px;
	color: g.$textColor;
	font-size: g.$xxl;
	line-height: 1.2;
	text-align: center;
	
	&__inner {
		display: inline-block;
		position: relative;
		padding: 0 0 10px;
		
		&::after {
			bottom: 0;
			content: '';
			width: 100%;
			border-bottom: 2px solid g.$baseColor;
			@include g.centering-elements(true, false);
		}
	}
	
	&--pink {
		.ttl02__inner {

			&::after {
				border-bottom: 2px solid g.$pink;
			}
		}
	}
}

.ttl03 {
	margin: 0 0 15px;
	padding: 0 0 10px;
	font-size: g.$xl;
	line-height: 1.4;
	position: relative;
	
	&::after {
		position: absolute;
		left: 0;
		bottom: 0;
		content: '';
		width: 60px;
		border-bottom: 2px solid g.$pink;
	}
}

.ttl04 {
	font-size: g.$l;
	margin: 0 0 15px;
	padding: 0 0 10px;
	border-bottom: 1px solid g.$borderColor;
	color: g.$baseColor;
}

.ttl05 {
	margin: 0 0 15px;
	padding: 0 0 0 12px;
	border-left: 2px solid g.$baseColor;
	font-size: g.$m;
	line-height: 1.4;
}

.ttl06 {
	margin: 0 0 15px;
	color: g.$pink;
	font-size: 1.5rem;
	line-height: 1.4;
}

.ttl07 {
	margin: 0 0 15px;
	font-size: g.$l;
	line-height: 1.4;
	color: g.$pink;
	text-align: center;
}


/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.column3,
.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.sp-column1 {
	margin-left: 0;
	margin-right: 0;

	&> * {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
}

.sp-column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.sp-column3 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}



/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&> * {
		flex: 0 0 auto;
		width: 100%;
		
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		
		img {
			display: block;
			margin: 0 auto;
		}

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {
	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}


	&--right {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}
}


.sp-float.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 20px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 5px;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}

	&--black {
		& > * {
			&::before {
				background: g.$textColor;
			}
		}
	}

	&--yellow {
		& > * {
			&::before {
				background: g.$baseColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 15px;
	background: #fff;

	&--gray {
		background: #f2f2f2;
	}

	&--border-gray {
		border: 1px solid g.$borderColor;
	}
	
	&--yellow {
		background: #faf5de;
	}
	
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	border: 1px solid g.$borderColor;
	table-layout: fixed;
	font-size: 1.5rem;
	line-height: 1.6;
	word-break: break-all;
	word-wrap: break-word;

	th,
	td {
		padding: 10px;
		background: #fff;
		border: 1px solid g.$borderColor;
		text-align: left;
		vertical-align: middle;
	}
	.bg-yellow {
		background: #faf5de;
	}
	.bg-gray {
		background: #f7f7f7;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
	
	
	&--sp-column1 {
		display: block;
		
		thead,
		tbody {
			display: block;
		}
		tr {
			display: block;
			&:first-child th {
				border-top: none;
			}
		}
		th,
		td {
			display: block;
			padding: 15px;
			border-left: none;
			border-right: none;
			border-bottom: none;
		}
	}
	
	&--wide {
		border: none;
		display: block;

		th,
		td {
			display: block;
			padding: 5px 8px;
			border-bottom-width: 0;
			border-right-width: 0;
			text-align: center;
		}
		th{  
			width: auto;
			text-align: left;
		}
		thead {
			display: block;
			float: left;
			overflow-x: scroll;
		}
		tbody {
			display: block;
			width: auto;
			overflow-x: auto;
			white-space: nowrap;

			tr { 
				display: inline-block; 
				margin: 0 -3px;

				&:last-child {
					th,
					td {
						border-right-width: 1px;
					}
				}
			}
		}
		thead th:last-child,
		tbody td:last-child {
			border-bottom-width: 1px;
		}
	}
	
	&--list {
		border: none;

		tr {
			display:block;
			margin: 0 0 20px;
		}
		th {
			display: block;
		}
		td {
			display: list-item;
			margin: 0 0 0 20px;
			padding: 5px 0 0;
			border: none;
			background: none;
		}
	}
	
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 200px;
	height: 50px;
	margin: 5px 0;
	padding: 0 30px;
	color: #fff;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	@include g.radius(50);
	z-index: 1;
	font-size: g.$m;
	
	.icon-fix-left,
	.icon-fix-right {
		@include g.centering-elements(false, true);
	}
	.icon-fix-left { left: 10px; }
	.icon-fix-right { right: 10px; }
	
	&--yellow {
		background: g.$baseColor;
	}
	&--pink {
		background: g.$pink;
	}
	&--gray {
		background: g.$gray;
		color: #333;
	}
	&--lg {
		width: 100%;
		height: 50px;
	}
	&--sm {
		display: inline-flex;
		min-width: 0;
		min-height: 35px;
		padding: 0 20px;
		font-size: g.$xxs;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 10px;

		& > * {
			display: block;
			padding: 5px 12px;
			background: #f2f2f2;
			border: 1px solid g.$borderColor;
			color: g.$textColor;
			text-decoration: none;
			@include g.radius(3);
			font-size: g.$s;
			
			&.current {
				background: g.$baseColor;
				border: 1px solid g.$baseColor;
				color: #fff;
				opacity: 1;
			}
		}
	}
	
	&__prev {
	}
	&__next {
	}
}

#news_index ,
#event_index ,
#action_akaishi ,
#action_higashi ,
#action_tomiya {
	.pager {
		flex-wrap: wrap;
	}
}

/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion,
.sp-accordion {
	position: relative;
	padding: 10px 30px 10px 10px;
	background: #f2f2f2;
	border: 1px solid g.$borderColor;
	cursor: pointer;
	line-height: 1.6;
	@include g.radius(3);
	
	&__icon {
		right: 10px;
		color: g.$pink;
		font-size: g.$s;
		@include g.centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 0;
	padding: 2px 10px;
	background: g.$pink;
	color: g.$white;
	font-size: g.$xxs;
	@include g.centering-elements(false, true);
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
}

input[type="text"],
textarea,
select {
	color: g.$textColor;
	font-size: 16px;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	@include g.radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	@include g.radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: g.$white;
	border: 1px solid #d2d2d2;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: g.$textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken(g.$borderColor, 5%);
			@include g.circle(14);
		}
		&::after {
			left: 5px;
			background: g.$pink;
			@include g.circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: g.$textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken(g.$borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: g.$pink;
			font-family: 'icomoon';
			font-size: g.$m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	font-weight: 600;
	
	&--red {
		color: g.$red;
	}
	
	&--green {
		color: g.$green;
	}

	&--pink {
		color: g.$pink;
	}
	
	&--purple {
		color: g.$purple;
	}
	
	&--blue {
		color: #56b4ca;
	}
}


/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	margin-bottom: 10px;
	
	&__item {
		padding: 20px 15px;
		background: g.$white;
		@include g.radius(5);
		@include g.box-shadow;
		text-decoration: none;
		@include g.transition;
		
		&--gray {
			border: 1px solid g.$borderColor;
		}
		
		&__date {
			margin: 0 0 5px;
			display: block;
			color: #7a7a7a;
			font-size: g.$xs;
		}
		
		&__label {
			margin: 0 0 5px;
			font-size: g.$s;
		}
		
		&__link {
			color: g.$textColor;
			font-weight: 600;
			width: 100%;
			margin: 5px 0 0;
			line-height: 1.4;
			
			.icon-angle-right {
				color: g.$baseColor;
				margin: 0 5px 0 0;
			}
		}
	}
}


/* --------------------------------------------------- */
/* info-list */
/* --------------------------------------------------- */
.info-list {
	margin: 0 0 30px;
	border-top: 1px solid g.$borderColor;
	
	&__item {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 15px 0;
		border-bottom: 1px solid g.$borderColor;
		
		.icon-file-pdf ,
		.icon-angle-right {
			color: g.$baseColor;
			padding: 0 5px 0 0;
		}
		
		.icon-filter_none {
			color: g.$baseColor;
			padding: 0 0 0 5px;
		}
		
		&__date {
			color: #7a7a7a;
			font-size: g.$xs;
			margin: 0 0 5px;
			
			@media screen and (min-width: 380px) and (max-width: 768px) {
				margin: 0 20px 0 0;
			}
		}
		
		&__label {
			margin: 0 0 5px;
			font-size: g.$s;
		}
		
		&__link {
			width: 100%;
			margin: 10px 0 0;
		}
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	&__ttl {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-end;
		margin: 0 0 30px;
	}
	&__block {
		padding: 0 0 30px;
		border-bottom: 1px solid g.$borderColor;
		overflow: hidden;
		
		img {
			width: 100%;
			height: auto;
		}
		
		p {
			margin-bottom: 0;
		}
		
		strong {
			font-weight: bold;
		}
		
		em {
			font-style: italic;
		}
		
		ul {
			list-style-type: none;
			& > * {
				position: relative;
				padding-left: 15px;
				margin-bottom: 5px;
				&::before {
					content: "";
					width: 6px;
					height: 6px;
					display: block;
					position: absolute;
					top: 0.7em;
					left: 0.2em;
					border-radius: 100%;
					background: g.$textColor;
				}
			}		
		}
		
		ol {
			list-style: decimal outside;
			margin: 0 0 0 20px;
			& > * {
				position: relative;
				padding-left: 0;
				margin-bottom: 5px;
			}		
		}
		
		blockquote {
			border-left: solid 3px g.$gray;
			padding: 0.4em 0.8em;
			margin: 5px auto 5px 1em;
			color: #6d6d6d;
		}
	}
}


/* --------------------------------------------------- */
/* bnr-sone */
/* --------------------------------------------------- */
.bnr-block {
	background: g.$white;
	padding: 30px 15px;
}

.bnr-sone {
	
	&__inner {
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

}


