@charset "utf-8";

@forward "_reset";
@forward "_icon";
@forward "global/_mixin";

@forward "pc/_base";
@forward "pc/_header";
@forward "pc/_contents";
@forward "pc/_footer";
@forward "pc/_index";
@forward "pc/_contact";
@forward "pc/_action";
@forward "pc/_about";

@forward "sp/_base";
@forward "sp/_header";
@forward "sp/_contents";
@forward "sp/_footer";
@forward "sp/_index";
@forward "sp/_contact";
@forward "sp/_action";
@forward "sp/_about";

@forward "_utility";

@import url('//fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&display=swap');
