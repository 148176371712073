@charset "utf-8";
@use "../global/_mixin" as g;

@media print, screen and (min-width: 767.5px) {
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
	overflow-y: scroll;
}

@media screen and (min-width: 767.5px) and (max-width: 1024px) {
	html {
		font-size: calc(100vw / 102.4);
	}
}

.wrap {
	color: g.$textColor;
	font-size: g.$m;
	line-height: 1.8;
	overflow: hidden;
	font-family: 'Noto Sans JP', sans-serif;
	background: url(/common/img/common/bg_pattern01.png) 0 0 repeat;
}

.sp-view {
	display: none !important;
}

a {
	color: g.$baseColor;
	text-decoration: underline;
	@include g.transition;
	
	&:hover {
		text-decoration: none;
		@include g.opacity;
	}
}


/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	margin: 0 0 35px;
	font-size: g.$xs;
	line-height: 1.4;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		max-width: 1000px;
		margin: 0 auto;
	}
	&__item {
		
		&:not(:last-child) {
			&::after {
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
				color: #acacac;
			}
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 50px;
	bottom: 50px;
	z-index: 5000;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 60px;
		height: 60px;
		background: rgba(g.$baseColor, 0.9);
		color: g.$white;
		font-size: 3.0rem;
		text-decoration: none;
		@include g.radius(50);
		@include g.transition;
		
		&:hover {
			@include g.opacity;
		}
	}
}
	
}