@charset "utf-8";
@use "../global/_mixin" as g;

@media screen and (max-width: 767.499px) {
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
}

body {
}

.wrap {
	color: g.$textColor;
	font-size: 1.5rem;
	line-height: 1.6;
	font-family: 'Noto Sans JP', sans-serif;
	overflow: hidden;
	background: url(/common/img/common/bg_pattern01.png) 0 0 repeat;
}

.pc-view {
	display: none !important;
}

a {
	color: g.$baseColor;
	text-decoration: underline;
}



/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	margin: 0 0 25px;
	font-size: g.$xxs;
	line-height: 1.4;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		
		&:not(:last-child) {
			&::after {
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
			}
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 15px;
	bottom: 15px;
	z-index: 1;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		background: rgba(g.$baseColor, 0.9);
		color: g.$white;
		font-size: g.$xxxl;
		line-height: 1.6;
		text-decoration: none;
		@include g.radius(50);
	}
}

}