@charset "utf-8";
@use "../global/_mixin" as g;

@media print, screen and (min-width: 767.5px) {
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	text-align: center;
	padding: 60px 0 45px;
	background: g.$white;
	
	.inner {
		max-width: 1200px;
		margin: 0 auto;
	}

	.footer-logo {
		margin: 0 0 65px;
		
		&__link {
			display: block;
			width: 140px;
			margin: 0 auto 20px;
			
			&__img {
				max-width: 125px;
			}
		}
		
		&__address {
			font-size: g.$s;
			margin: 0 0 30px;
		}
		
		.column2 {
			align-items: center;
			justify-content: center;
			margin-bottom: 0;
			
			& > * {
				width: 190px;
				margin-bottom: 0;
			}
			
			.btn {
				width: 100%;
				font-size: 1.5rem;
			}
		}
	}
	
	.fnav {
		border-top: 1px dotted #d0d0d0;
		border-bottom: 1px dotted #d0d0d0;
		padding: 20px 50px;
		margin: 0 0 45px;
		font-size: 1.5rem;
		
		@media screen and (max-width: 1219px) {
			margin: 0 20px 45px;
		}
		
		&__inner {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
		}
		
		&__item {
			margin: 0 45px 0 0;
			text-decoration: none;
			color: g.$textColor;
			
			.icon-angle-right {
				color: g.$baseColor;
				margin: 0 5px 0 0;
			}
		}
	}

	.copyright {
		color: #848484;
		font-size: g.$xxs;
	}
}
	
}