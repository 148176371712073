@charset "utf-8";
@use "../global/_mixin" as g;

@media screen and (max-width: 767.499px) {
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	
	.wrap {
		background: g.$white;
	}
	
	/* ============================================================ */
	/* .contents */
	/* ============================================================ */
	.contents {
		padding: 0;
		display: flex;
		flex-direction: column;
	}
	
	/* --------------------------------------------------- */
	/* .index-visual */
	/* --------------------------------------------------- */
	.index-visual {
		width: 100vw;
		display: flex;
		flex-direction: column;
		text-align: center;
		order: 1;
		
		&__img {
			width: 100%;
			height: 330px;
			background: url(/common/img/index/pic_visual01.png) center bottom no-repeat;
			background-size: cover;
			min-height: inherit;
			
			@media screen and (min-width: 495px) and (max-width: 767.5px) {
				height: 500px;
			}
		}
		
		&__inner {
			width: 100%;
			background: url(/common/img/index/bg_visual.png) bottom right no-repeat;
			background-size: 290px;
			margin: -50px 0 0;
			padding: 0 0 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			text-align: center;

			&__ttl {
				
				&__logo {
					width: 120px;
					margin: 0 auto;
					
					&__hojin {
						width: 110px !important;
						margin: 10px 0 5px;
					}
					
					&__txt {
						margin: 0 0 15px;
					}
				}
				
				&__txt {
					font-size: 2.1rem;
					color: g.$textColor;
					line-height: 1.2;
					position: relative;
					
					&__sub {
						font-size: g.$m;
						margin: 10px 0 0;
						display: inline-block;
					}
					
					&::before {
						content: "";
						position: absolute;
						top: 0;
						left: -20px;
						width: 20px;
						height: 16px;
						background: url(/common/img/index/bg_visual_left.png) center center no-repeat;
						background-size: cover;
					}
					
					&::after {
						content: "";
						position: absolute;
						top: 0;
						right: -20px;
						width: 20px;
						height: 16px;
						background: url(/common/img/index/bg_visual_right.png) center center no-repeat;
						background-size: cover;
					}
				}
			}
		}
	}
	
	
	/* --------------------------------------------------- */
	/* .index-news */
	/* --------------------------------------------------- */
	.index-news {
		background: url(/common/img/common/bg_pattern01.png) 0 0 repeat;
		padding: 35px 15px;
		order: 2;
	}
	
	/* --------------------------------------------------- */
	/* .index-recruit */
	/* --------------------------------------------------- */
	.index-recruit {
		background: url(/common/img/common/bg_pattern02.png) 0 0 repeat;
		padding: 35px 15px;
		order: 5;
	}
	
	
	/* --------------------------------------------------- */
	/* .index-about */
	/* --------------------------------------------------- */
	.index-about {
		background: url(/common/img/index/bg_about.png) center top no-repeat;
		background-size: cover;
		padding: 35px 15px;
		order: 3;
		
		&__inner {
			
			&__img {
				margin: 0 0 20px;
				padding: 25px;
				border: 1px solid g.$borderColor;
				background: g.$white;
			}
		}
		
		.btn {
			width: 100%;
		}
	}
	
	
	/* --------------------------------------------------- */
	/* .index-club */
	/* --------------------------------------------------- */
	.index-club {
		background: url(/common/img/common/bg_pattern01.png) 0 0 repeat;
		padding: 35px 15px;
		order: 4;
		
		&__introduction {
			margin: 20px 0;
			
			&__inner {
				margin: 0 0 20px;
				
				&__news {
					background: g.$white;
					@include g.radius(5);
					@include g.box-shadow;
					@include g.transition;
					margin: -25px 15px 4px;
					padding: 15px 15px 20px;
					position: relative;
					z-index: 1;
				}
				
				&__ttl {
					border-bottom: 1px dotted #d9d9d9;
					padding: 0 0 5px;
					margin: 0 0 10px;
					font-size: g.$m;
					font-weight: 600;
					
					.icon-angle-right {
						color: g.$baseColor;
					}
					
					&__link {
						text-decoration: none;
					}
				}
				
				&__link {
					color: g.$textColor;
					text-decoration: none;
					display: block;
					line-height: 1.4;
				}

			}
		}
	}
	
	/* --------------------------------------------------- */
	/* .bnr-block */
	/* --------------------------------------------------- */
	.bnr-block {
		order: 6;
	}
	
}

}