@charset "utf-8";
@use "../global/_mixin" as g;

@media print, screen and (min-width: 767.5px) {
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	padding: 0 0 60px;
	
	&__inner {
		background: g.$white;
		max-width: 1200px;
		margin: -118px auto 0;
		padding: 60px 100px 100px;
		@include g.radius(5);
		
		@media screen and (max-width: 1219px) {
			margin: -118px 20px 0;
			padding: 50px 60px 60px;
		}
	}
	
}


/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 15px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 430px;
	font-size: 3.6rem;
	font-weight: g.$font-medium;
	line-height: 1.2;
	background: g.$baseColor;
	color: g.$white;
	
	&__inner {
		width: 1200px;
		margin: 0 auto;
		display: block;
		text-align: left;
		
		@media screen and (max-width: 1219px) {
			margin: 0 20px;
			width: 100%;
		}
	}
}

.ttl02 {
	margin: 0 0 40px;
	color: g.$textColor;
	font-size: 3.0rem;
	font-weight: g.$font-regular;
	line-height: 1.2;
	text-align: center;
	
	&__inner {
		display: inline-block;
		position: relative;
		padding: 0 0 20px;
		
		&::after {
			bottom: 0;
			content: '';
			width: 100%;
			border-bottom: 2px solid g.$baseColor;
			@include g.centering-elements(true, false);
		}
	}
	
	&--pink {
		.ttl02__inner {

			&::after {
				border-bottom: 2px solid g.$pink;
			}
		}
	}
}

.ttl03 {
	margin: 0 0 30px;
	padding: 0 0 15px;
	font-size: 2.6rem;
	line-height: 1.4;
	position: relative;
	
	&::after {
		position: absolute;
		left: 0;
		bottom: 0;
		content: '';
		width: 80px;
		border-bottom: 2px solid g.$pink;
	}
}

.ttl04 {
	font-size: g.$xxl;
	margin: 0 0 20px;
	padding: 0 0 10px;
	border-bottom: 1px solid g.$borderColor;
	color: g.$baseColor;
}

.ttl05 {
	margin: 0 0 20px;
	padding: 0 0 0 15px;
	border-left: 2px solid g.$baseColor;
	font-size: g.$l;
	line-height: 1.4;
}

.ttl06 {
	margin: 0 0 15px;
	color: g.$pink;
	font-size: 1.7rem;
	line-height: 1.4;
}

.ttl07 {
	margin: 0 0 25px;
	font-size: 2.6rem;
	line-height: 1.4;
	color: g.$pink;
	text-align: center;
}


/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	 margin-left: -5px;
	 margin-right: -5px;

	 &> * {
		 margin-left: 5px;
		 margin-right: 5px;
		 width: calc((100% - 21px) / 2 );
	 }
 }

.column3 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 61px) / 3);
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 14.6%;
	}
}


/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;

	&> * {
		flex: 0 0 auto;

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			max-width: 50%;

			* {
				width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			max-width: 50%;

			* {
				width: 100%;
			}

		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 15px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 0;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}
	
	&--black {
		& > * {
			&::before {
				background: g.$textColor;
			}
		}
	}

	&--yellow {
		& > * {
			&::before {
				background: g.$baseColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 25px;
	box-sizing: border-box;
	background: g.$white;

	&--gray {
		background: #f2f2f2;
	}

	&--border-gray {
		border: 1px solid g.$borderColor;
	}
	
	&--yellow {
		background: #faf5de;
	}
	
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	
	th,
	td {
		padding: 15px;
		background: g.$white;
		border: 1px solid g.$borderColor;
		font-size: g.$m;
		text-align: left;
		vertical-align: middle;
		line-height: 1.6;
	}
	.bg-yellow {
		background: #faf5de;
	}
	.bg-gray {
		background: #f7f7f7;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
}

.table--center {
	th,
	td {
		text-align: center;
	}
}

.table--thin {
	th,
	td {
		padding: 5px 10px;
		line-height: 1.4;
	}
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 190px;
	height: 50px;
	margin: 0 5px;
	padding: 0 30px;
	color: g.$white;
	font-size: g.$m;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	cursor: pointer;
	transition: 0.3s all;
	@include g.radius(50);
	@include g.transition;
	z-index: 0;

	.icon-fix-left,
	.icon-fix-right {
		@include g.centering-elements(false, true);
	}
	.icon-fix-left { left: 15px; }
	.icon-fix-right { right: 15px; }
	
	&:hover {
		opacity: 0.7;
		text-decoration: none;
	}
	
	&--yellow {
		background: g.$baseColor;
	}
	&--pink {
		background: g.$pink;
	}
	&--gray {
		background: g.$gray;
		color: #333;
	}
	&--lg {
		min-width: 300px;
		height: 60px;
		font-size: g.$m;
	}
	&--sm {
		min-width: inherit;
		height: 40px;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 5px;

		& > * {
			display: block;
			padding: 10px 15px;
			background: #f2f2f2;
			border: 1px solid g.$borderColor;
			color: g.$textColor;
			text-decoration: none;
			@include g.radius(5);
			
			&.current,
			&:hover {
				background: g.$baseColor;
				border: 1px solid g.$baseColor;
				color: g.$white;
				opacity: 1;
				text-decoration: none;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion ,
.sp-accordion {
	position: relative;
	padding: 10px 30px 10px 10px;
	background: #f2f2f2;
	border: 1px solid g.$borderColor;
	cursor: pointer;
	line-height: 1.6;
	@include g.radius(3);
	
	&__icon {
		right: 10px;
		color: g.$pink;
		@include g.centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 10px;
	padding: 2px 10px;
	background: g.$pink;
	color: g.$white;
	font-size: g.$xxs;
	@include g.centering-elements(false, true);
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	color: g.$textColor;
	font-size: g.$m;
	@include g.radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	color: g.$textColor;
	@include g.radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: g.$white;
	border: 1px solid #d2d2d2;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: g.$textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken(g.$borderColor, 5%);
			@include g.circle(14);
		}
		&::after {
			left: 5px;
			background: g.$pink;
			@include g.circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: g.$textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken(g.$borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: g.$pink;
			font-family: 'icomoon';
			font-size: g.$m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	font-size: 1.5rem;
	font-weight: g.$font-medium;
	
	&--red {
		color: g.$red;
	}
	
	&--green {
		color: g.$green;
	}

	&--pink {
		color: g.$pink;
	}
	
	&--purple {
		color: g.$purple;
	}
	
	&--blue {
		color: #56b4ca;
	}
}


/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	margin-bottom: 50px;
	
	&__item {
		padding: 40px 30px 45px;
		background: g.$white;
		@include g.radius(5);
		@include g.box-shadow;
		text-decoration: none;
		@include g.transition;
		
		@media screen and (max-width: 1219px) {
			padding: 25px 25px 25px;
		}

		&:hover {
			background: g.$white;
			opacity: 1;
			
			.label, .news-list__item__date, .news-list__item__link, .icon-angle-right {
				@include g.opacity;
			}
			
			.news-list__item__link {
				text-decoration: underline;
			}
			
			.icon-angle-right {
				text-decoration: none !important;
			}
		}
		
		&--gray {
			border: 1px solid g.$borderColor;
		}
		
		&__date {
			margin: 0 0 15px;
			display: block;
			color: #7a7a7a;
			font-size: 1.5rem;
			
			@media screen and (max-width: 1219px) {
				margin: 0 0 10px;
			}
		}
		
		&__label {
			margin: 0 0 5px;
			font-size: 1.5rem;
		}
		
		&__link {
			color: g.$textColor;
			font-weight: g.$font-medium;

			.icon-angle-right {
				color: g.$baseColor;
				margin: 0 5px 0 0;
			}
		}
	}
}



/* --------------------------------------------------- */
/* info-list */
/* --------------------------------------------------- */
.info-list {
	margin: 0 0 60px;
	border-top: 1px solid g.$borderColor;
	
	&__item {
		display: flex;
		padding: 15px 0;
		border-bottom: 1px solid g.$borderColor;
		
		.icon-file-pdf ,
		.icon-angle-right {
			color: g.$baseColor;
			padding: 0 10px 0 0;
		}
		
		.icon-filter_none {
			color: g.$baseColor;
			padding: 0 0 0 10px;
		}
		
		&__date {
			margin: 0 20px 0 0;
			color: #7a7a7a;
		}
		&__link {
			flex: 1 1 auto;
		}
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	&__ttl {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		align-items: center;
		margin: 0 0 50px;
	}
	&__block {
		padding: 0 0 50px;
		border-bottom: 1px solid g.$borderColor;
		
		p {
			margin-bottom: 0;
		}
		
		strong {
			font-weight: bold;
		}
		
		em {
			font-style: italic;
		}

		ul {
			list-style-type: none;
			& > * {
				position: relative;
				padding-left: 15px;
				margin-bottom: 5px;
				&::before {
					content: "";
					width: 6px;
					height: 6px;
					display: block;
					position: absolute;
					top: 0.7em;
					left: 0.2em;
					border-radius: 100%;
					background: g.$textColor;
				}
			}		
		}
		
		ol {
			list-style: decimal outside;
			margin: 0 0 0 20px;
			& > * {
				position: relative;
				padding-left: 0;
				margin-bottom: 5px;
			}		
		}
		
		blockquote {
			border-left: solid 3px g.$gray;
			padding: 0.4em 0.8em;
			margin: 5px auto 5px 1em;
			color: #6d6d6d;
		}
		
	}
}


/* --------------------------------------------------- */
/* bnr-sone */
/* --------------------------------------------------- */
.bnr-block {
	border-bottom: 1px solid g.$borderColor;
	background: g.$white;
	padding: 60px 0;
	
	@media screen and (max-width: 1219px) {
		padding: 40px 0;
	}
}

.bnr-sone {
	max-width: 892px;
	margin: 0 auto;
	padding: 0 20px;
}
	
}

