@charset "utf-8";
@use "../global/_mixin" as g;

@media print, screen and (min-width: 767.5px) {
/* ============================================================ */
/* about */
/* ============================================================ */
.about {
	&__system {
		padding: 50px 100px;
		border: 1px solid g.$borderColor;
		background: g.$white;
	}
}

}