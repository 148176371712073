@charset "utf-8";
@use "../global/_mixin" as g;

@media print, screen and (min-width: 767.5px) {
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	padding: 40px 0 0;
	position: fixed;
	width: 100%;
	z-index: 2000;
	
	.inner {
		background: g.$white;
		max-width: 1200px;
		height: 80px;
		@include g.radius(5);
		box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
		margin: 0 auto;
		padding: 0 45px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		
		@media screen and (max-width: 1095px) {
			padding: 0 25px;
		}
		
		@media screen and (max-width: 1219px) {
			margin: 0 20px;
		}
	}
	
	.header-logo {
		
		&__inner {
			width: 186px;
			display: flex;
			align-items: center;
			
			@media screen and (max-width: 840px) {
				width: 150px;
			}
			
			&__img {
				margin: 0 10px 0 0;
				width: 50px;
				
				@media screen and (max-width: 840px) {
					width: 35px;
				}
			}
			
			&__txt {
				display: flex;
				align-items: center;
				flex-direction: column;
				line-height: 1.0;
				
				&__hojin , &__logo {
					width: 126px;
					
					@media screen and (max-width: 840px) {
						width: 100px;
					}
				}
				
				&__hojin {
					margin: 0 0 5px;
				}
			}
		}
	}

	.gnav {
		
		&__inner {
			display: flex;
		}
		
		&__item {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 40px 0 0;
			color: g.$textColor;
			text-decoration: none;
			font-size: g.$m;
			
			@media screen and (max-width: 1155px) {
				margin: 0 25px 0 0;
			}
			@media screen and (max-width: 1055px) {
				margin: 0 20px 0 0;
			}
			@media screen and (max-width: 955px) {
				margin: 0 15px 0 0;
			}
		}
		
		&--contact {
			background: g.$pink;
			min-width: 175px; 
			height: 50px;
			margin: 0!important;
			@include g.radius(5);
			color: g.$white;
			@include g.transition;
			
			@media screen and (max-width: 1055px) {
				min-width: 100px; 
			}
			
			&:hover {
				opacity: 0.7;
			}
		}

	}
}
	
}


