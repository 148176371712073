@charset "utf-8";
@use "../global/_mixin" as g;

@media screen and (max-width: 767.499px) {
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	border-top: 1px solid g.$borderColor;
	text-align: center;
	padding: 25px 0 20px;
	background: g.$white;

	.footer-logo {
		margin: 0 0 20px;
		
		&__link {
			display: block;
			width: 110px;
			margin: 0 auto 15px;
			
			&__img {
				max-width: 70px;
			}
		}
		
		&__address {
			font-size: g.$xs;
			margin: 0 0 15px;
		}
		
		.column2 {
			align-items: center;
			justify-content: center;
			margin: 0;
			
			& > * {
				margin-left: 5px;
				margin-right: 5px;
				width: calc((100% - 40px) / 2 );
			}
			
			.btn {
				min-width: 100% !important;
				padding: 0;
				font-size: 1.35rem;
			}
		}
	}
	
	.fnav {
		margin: 0 0 20px;
		
		&__inner {
			display: flex;
			flex-flow: row wrap;
			text-align: left;
			border-top: 1px dotted #d0d0d0;
			border-bottom: 1px dotted #d0d0d0;
		}
		
		&__item {
			color: g.$textColor;
			width: 50%;
			padding: 15px;
			border-bottom: 1px dotted #d0d0d0;
			border-right: 1px dotted #d0d0d0;
			text-decoration: none !important;
			line-height: 1.4;
			font-size: g.$s;
			
			&:nth-child(2n+0) {
				border-right: none !important;
			}
			
			&:nth-last-of-type(2),
			&:last-of-type {
				border-bottom: none !important;
			}
			
			&__text {
				display: table;
			}
			
			.icon-angle-right {
				padding: 0 5px 0 0;
				display: table-cell;
				white-space: nowrap;
				color: g.$baseColor;
			}
		}
	}
		
	.copyright {
		color: #848484;
		font-size: g.$xxs;
	}
}

}