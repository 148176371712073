@charset "utf-8";
@use "../global/_mixin" as g;

@media print, screen and (min-width: 767.5px) {
/* ============================================================ */
/* contact */
/* ============================================================ */
.contact {
	&__inner {
		max-width: 700px;
		margin: 0 auto;
		text-align: center;
		font-size: g.$xl;
		line-height: 1.6;
	}
	
	&__tel {
		color: g.$pink;
		font-weight: g.$font-medium;
		
		.tel {
			font-size: 2.8rem;
		}
	}
	
}
	
}