@charset "utf-8";
@use "../global/_mixin" as g;

@media screen and (max-width: 767.499px) {
/* ============================================================ */
/* about */
/* ============================================================ */
.about {
	&__system {
		padding: 25px;
		border: 1px solid g.$borderColor;
		background: g.$white;
	}
}

}