@charset "utf-8";

$baseColor: #dbbd1d;
$textColor: #422b1b;
$borderColor: #e7e7e7;

$white: #fff;
$pink: #df767a;
$green: #2f9f56;
$purple: #bb4d9f;
$red: #e24d4d;
$gray: #d3d3d3;

@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin radius($size) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
}

@mixin transition {
	transition: 0.3s all;
}

@mixin opacity($opacity: 0.7) {
	opacity: $opacity;
}

@mixin centering-elements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}

@mixin box-shadow {
	 box-shadow: 0 3px 3px 0 rgba(0,0,0,0.1);
 }

/* font-size
-------------------------------------------------- */
$xxxs: 1.0rem;
$xxs: 1.2rem;
$xs: 1.3rem;
$s: 1.4rem;
$m: 1.6rem;
$l: 1.8rem;
$xl: 2.0rem;
$xxl: 2.2rem;
$xxxl: 2.4rem;

/* font-weight
-------------------------------------------------- */
$font-regular: 400;
$font-medium: 500;


/* scrool btn
-------------------------------------------------- */
@-webkit-keyframes ani-mouse {
	 0% {
	 opacity: 1;
	 top: 29%;
	}
	 15% {
	 opacity: 1;
	 top: 50%;
	}
	 50% {
	 opacity: 0;
	 top: 50%;
	}
	 100% {
	 opacity: 0;
	 top: 29%;
	}
}
@-moz-keyframes ani-mouse {
	 0% {
	 opacity: 1;
	 top: 29%;
	}
	 15% {
	 opacity: 1;
	 top: 50%;
	}
	 50% {
	 opacity: 0;
	 top: 50%;
	}
	 100% {
	 opacity: 0;
	 top: 29%;
	}
}
@keyframes ani-mouse {
	 0% {
	 opacity: 1;
	 top: 29%;
	}
	 15% {
	 opacity: 1;
	 top: 50%;
	}
	 50% {
	 opacity: 0;
	 top: 50%;
	}
	 100% {
	 opacity: 0;
	 top: 29%;
	}
}
