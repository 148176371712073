@charset "utf-8";
@use "../global/_mixin" as g;

@media print, screen and (min-width: 767.5px) {
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	
	.wrap {
		background: g.$white;
	}
	
	/* ============================================================ */
	/* .contents */
	/* ============================================================ */
	.contents {
		padding: 0;
	}
	
	/* --------------------------------------------------- */
	/* .index-visual */
	/* --------------------------------------------------- */
	.index-visual {
		display: flex;
		background: url(/common/img/index/bg_visual.png) bottom right no-repeat;
		background-size: 656px;
		width: 100vw;
		height: 100vh;
		
		@media screen and (max-height: 720px) {
			height: 750px;
		}
		
		&__img {
			width: 50vw;
			height: 100vh;
			background: url(/common/img/index/pic_visual01.png) center 0 no-repeat;
			background-size: cover;
			
			@media screen and (max-height: 720px) {
				height: 750px;
			}
		}
		
		&__inner {
			width: 50vw;
			max-width: 600px;
			height: 100vh;
			padding: 120px 0 0;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			text-align: center;
			
			@media screen and (max-height: 720px) {
				height: 750px;
				padding: 0;
			}
			
			&__ttl {
				margin: 0 0 40px;
				width: auto;
				height: auto;
				
				&__logo {
					width: 250px;
					margin: 0 auto;
					
					&__hojin {
						width: 160px !important;
						margin: 20px 0 10px;
					}
					
					&__txt {
						width: 210px !important;
						margin: 0 0 30px;
					}
				}
				
				&__txt {
					font-size: g.$xxxl;
					color: g.$textColor;
					line-height: 1.2;
					font-weight: g.$font-medium;
					position: relative;
					
					&__sub {
						font-size: g.$l;
						margin: 10px 0 0;
						display: inline-block;
					}
					
					&::before {
						content: "";
						position: absolute;
						top: 0;
						left: -40px;
						width: 28px;
						height: 22px;
						background: url(/common/img/index/bg_visual_left.png) center center no-repeat;
						background-size: cover;
					}
					
					&::after {
						content: "";
						position: absolute;
						top: 0;
						right: -40px;
						width: 28px;
						height: 22px;
						background: url(/common/img/index/bg_visual_right.png) center center no-repeat;
						background-size: cover;
					}
				}
			}
		}
		
		.scroll-btn {
			display: block;
			text-align: center;
			
			& > * {
				font-size: g.$xxs;
				font-weight: normal;
				color: g.$baseColor;
			}
			
			&*:hover, &*:focus, &*.active {
				color: g.$baseColor;
			}
			
			&*:hover, &*:focus, &*:active, &*.active {
				opacity: 0.8;
				filter: alpha(opacity=80);
			}
			
			.mouse {
				position: relative;
				display: block;
				width: 26px;
				height: 44px;
				margin: 0 auto 5px;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				border: 3px solid g.$baseColor;
				border-radius: 23px;
				
				& > * {
					position: absolute;
					display: block;
					top: 29%;
					left: 50%;
					width: 5px;
					height: 5px;
					margin: -4px 0 0 -2px;
					background: g.$baseColor;
					border-radius: 50%;
					-webkit-animation: ani-mouse 2.5s linear infinite;
					-moz-animation: ani-mouse 2.5s linear infinite;
					animation: ani-mouse 2.5s linear infinite;
				}	
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* .index-news */
	/* --------------------------------------------------- */
	.index-news {
		background: url(/common/img/common/bg_pattern01.png) 0 0 repeat;
		padding: 80px 0;
		
		@media screen and (max-width: 1219px) {
			padding: 60px 20px;
		}
		
		&__inner {
			max-width: 1200px;
			margin: 0 auto;
		}
		
		.btn {
			width: 250px;
		}
	}
	
	/* --------------------------------------------------- */
	/* .index-recruit */
	/* --------------------------------------------------- */
	.index-recruit {
		background: url(/common/img/common/bg_pattern02.png) 0 0 repeat;
		padding: 80px 0;
		
		@media screen and (max-width: 1219px) {
			padding: 60px 20px;
		}
		
		&__inner {
			max-width: 1200px;
			margin: 0 auto;
		}
		
		.btn {
			width: 250px;
		}
	}

	
	/* --------------------------------------------------- */
	/* .index-about */
	/* --------------------------------------------------- */
	.index-about {
		background: url(/common/img/index/bg_about.png) center top no-repeat;
		background-size: cover;
		padding: 80px 0;
		
		@media screen and (max-width: 1219px) {
			padding: 60px 20px;
		}
		
		&__inner {
			max-width: 1200px;
			margin: 0 auto;
			text-align: center;
			
			&__img {
				margin: 0 auto 40px;
				padding: 50px;
				max-width: 850px;
				border: 1px solid g.$borderColor;
				background: g.$white;
			}
		}
		
		.btn {
			width: 320px;
			margin: 30px 0 0;
		}
	}
	
	
	/* --------------------------------------------------- */
	/* .index-club */
	/* --------------------------------------------------- */
	.index-club {
		background: url(/common/img/common/bg_pattern01.png) 0 0 repeat;
		padding: 80px 0;
		
		@media screen and (max-width: 1219px) {
			padding: 60px 20px;
		}
				
		&__inner {
			max-width: 1200px;
			margin: 0 auto;
		}
		
		&__introduction {
			margin: 45px 0;
			display: flex;
            justify-content: center;
			
			&__inner {
				width: calc(100% / 3);
				overflow: hidden;
				display: flex;
				flex-direction: column;
                @media screen and (max-width: 1024px) {
                    width: calc(100% / 2);
                }
				
				&__news {
					background: g.$white;
					@include g.radius(5);
					@include g.box-shadow;
					@include g.transition;
					margin: -25px 30px 4px;
					padding: 25px 25px 35px;
					position: relative;
					z-index: 1000;
					flex: 1 1 auto;
					
					@media screen and (max-width: 1219px) {
						margin: -25px 20px 4px;
						padding: 20px 20px 25px;
					}
				}
				
				&__ttl {
					border-bottom: 1px dotted #d9d9d9;
					padding: 0 0 10px;
					margin: 0 0 10px;
					font-weight: g.$font-medium;
					font-size: 1.7rem;
					
					.icon-angle-right {
						color: g.$baseColor;
					}
					
					&__link {
						text-decoration: none;
						
						&:hover {
							text-decoration: underline;
						}
					}
				}
				
				&__link {
					color: g.$textColor;
					text-decoration: none;
					display: block;
					line-height: 1.4;
					
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
		
		.btn {
			width: 250px;
		}
	}
	
	
}
	
}