@charset "utf-8";
@use "../global/_mixin" as g;

@media screen and (max-width: 767.499px) {
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	padding: 15px 0 0;
	position: fixed;
	width: 100%;
	z-index: 2;
	
	.inner {
		background: g.$white;
		height: 65px;
		@include g.radius(5);
		box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
		margin: 0 15px;
		padding: 0 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.header-logo {
		flex: 1 1 auto;
		display: flex;
		align-items: center;
		height: 60px;
		
		&__inner {
			display: flex;
			align-items: center;
			
			&__img {
				margin: 0 10px 0 0;
				width: 40px;
			}
			
			&__txt {
				display: flex;
				align-items: center;
				flex-direction: column;
				line-height: 1.0;
				
				&__hojin , &__logo {
					width: 100px;
				}
				
				&__hojin {
					margin: 0 0 3px;
				}
			}
		}
	}
	
	.header-menu,
	.header-menu span {
		@include g.transition;
	}

	.header-menu {
		position: relative;
		width: 28px;
		height: 22px;
		z-index: 10;
		cursor: pointer;

		&__item {
			display: inline-block;
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			background: g.$pink;
			@include g.radius(2);

			&:nth-of-type(1) { top: 0; }
			&:nth-of-type(2) { top: 10px; }
			&:nth-of-type(3) { bottom: 0; }
		}
		&.active {
			z-index: 1 !important;
			
			.header-menu__item {
				background: g.$white;
				
				&:nth-of-type(1) { transform: translateY(10px) rotate(-315deg); }
				&:nth-of-type(2) { opacity: 0; }
				&:nth-of-type(3) { transform: translateY(-10px) rotate(315deg); } 
			}
		}
	}

	.gnav {
		position: fixed;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		padding: 15px 15px 0;
		background: rgba(g.$baseColor, 0.95);
		@include g.transition;
		display: none;
		overflow-y: auto;
		visibility: hidden;

		&__inner {
			display: flex;
			flex-flow: row wrap;
			border-top: 1px dotted g.$white;
			border-bottom: 1px dotted g.$white;
			margin: 0 -15px;
		}
		
		&__item {
			color: g.$white;
			width: 50%;
			padding: 15px;
			border-bottom: 1px dotted g.$white;
			border-right: 1px dotted g.$white;
			text-decoration: none !important;
			line-height: 1.4;
			
			&:nth-child(2n+0) {
				border-right: none !important;
			}
			
			&:nth-last-of-type(2),
			&:last-of-type {
				border-bottom: none !important;
			}
			
			&__text {
				display: table;
			}
			
			.icon-angle-right {
				padding: 0 5px 0 0;
				display: table-cell;
				white-space: nowrap;
			}
		}
		
		&__btn {
			&--bottom {
				margin: 20px auto 30px;
				text-align: center;
				color: g.$white;
				cursor: pointer;
				width: 55px;
				height: 60px;
				text-align: center;

				.icon-close {
					font-size: 3.0rem;
					display: block;
					line-height: 1.2;
				}
			}
			
			&--top {
				color: g.$white;
				width: 60px;
				height: 60px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: g.$xxxl;
				margin: 0 0 0 auto;

				.icon-close {
				}
			}
		}
		
		.show & {
			z-index: 1;
			display: block;
			visibility: visible;
		}
	}
}

}

